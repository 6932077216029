.inox {
  /* padding: 80px 0 0px; */
  /* background-color: #f9f7f2; */
}

.inox hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 40px;
  opacity: 1;
}

.inox .row {
  margin-top: -30px;
}

.inox h2 {
  color: #dbb85c;
  text-align: center;
  white-space: nowrap;
}

.inox img {
  height: 350px;
  width: 80%;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 20px;
  /* margin-left:-40px; */
}
.tAZei figure {
  /* margin-left:-70px; */
  opacity: 1 !important;
  z-index: 100 !important;
}
.tAZei {
  max-width: 100% !important;
  /* padding: 0 !important; */
}

.tAZei .coverflow__container__1P-xE {
  /* width: 100% !important; */
  height: 880px !important;
}

.coverflow__stage__14oqC {
  padding-bottom: 0px;
}

.coverflow__figure__3bk_C {
  /* transform: translateX(-90px)!important; */
  /* transform: translateX(-155px) scale(2) !important; */
}

.nav-btn svg {
  color: #ab7f16;
}

.inox .check-btn {
  padding: 50px 0 0px;
}
.crvYzy .nav-btn {
  border: 1px solid #ab7f16 !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .inox {
    padding: 0px 0 0px;
  }
  .inox .row {
    margin-top: 0;
  }
  .inox h2 {
    font-size: 35px;
  }
  .inox img {
    height: 450px;
    width: 100%;
  }
  .inox .check-btn button {
    font-size: 18px;
    width: auto;
    padding: 0 20px;
    height: 50px;
    border: 1px solid #dbb85c !important;
    background-color: #fff;
    color: #000;
    font-weight: 700;
    font-family: "Playfair Display", serif;
  }
  .inox .check-btn {
    padding: 10px 0 30px;
    text-align: center;
  }
  .tAZei {
    max-width: 100% !important;
    padding: 20px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
