.events hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 45px 0 0 40px;
  opacity: 1;
  margin-left: auto;
}

.events {
  /* background-color: #f9f7f2; */
}

.events #tabs menu {
  display: flex;
  justify-content: space-between;
  margin: 60px 0 0;
  padding-left: 0;
}

.events #tabs button {
  font-size: 19px !important;
  color: #949494;
  display: flex;
  font-family: Avenir;
  font-weight: 600;
  background-color: transparent;
  border:none;
}

.events #tabs button:hover {
  color: #ab7f16;
}

.events #tabs button.active .dot {
  display: block !important;
}

.events #tab-content {
  padding: 40px 0 0;
}

.events .dot {
  width: 11px;
  height: 11px;
  background-color: #ab7f16;
  border-radius: 50%;
  margin: 10px 10px 0 0;
  display: none;
}

.events h2 {
  color: #dbb85c;
  margin: 10px 0;
  white-space: nowrap;
}

.events video {
  height: 500px !important;
  object-fit: cover;
}

.events .text {
  padding: 30px;
}

.events h3 {
  font-size: 23px;
  font-weight: 600;
  font-family: Avenir;
}

.events p {
  font-size: 18px !important;
  margin-top: 15px;
}

.events p span {
  font-weight: 600;
}

.events .view-btn button {
  font-size: 23px;
  font-weight: 600;
  color: #000;
  font-family: Didot;
  display: flex;
  border: 4px solid #dbb85c;
  width: 210px;
  height: 65px;
  justify-content: center;
  align-items: center;
  margin: 0px auto 0px;
  letter-spacing: 1px;
      background-color: #fff;
}

.events ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .events #tabs menu {
    margin: 10px 0px 10px;
    display: block;
    column-count: 3;
    padding-left: 0;
  }
  .events .dot {
    width: 7px;
    height: 7px;
    margin: 6px 7px 0 0;
  }
  .events #tabs button {
    font-size: 13px !important;
  }
  .events #tab-content {
    padding: 0px;
  }
  .events .text {
    padding: 15px 0px 0px;
  }
  .events h2 {
    font-size: 35px;
  }
  .events h3 {
    font-size: 18px !important;
  }
  .events p {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px !important;
  }
  .events .view-btn button {
    font-size: 18px;
    width: 145px;
    height: 50px;
    margin: 20px auto 0px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
