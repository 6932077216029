.leisure_inox hr,
.leisure_park hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 45px 0 0 40px;
  opacity: 1;
}

.leisure_inox h2,
.leisure_park h2 {
  color: #dbb85c;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.leisure_inox .carousel-indicators button {
  width: 12px !important;
}

.leisure .reverse .row {
  flex-direction: row-reverse;
}

.leisure .reverse .text {
  margin-left: 80px;
}

.leisure .help {
  padding: 0 !important;
}

.leisure .navbar {
  background-color: #000 !important;
}

.leisure .inox {
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 50px;
}

.leisure .inox .check-btn {
  display: none;
}

.leisure_spa .carousel3 .text,
.leisure_spa .carousel3 .d-flex,
.leisure_park .carousel3 .text,
.leisure_park .carousel3 .d-flex {
  display: none !important;
}

.leisure_spa .carousel3,
.leisure_park .carousel3 {
  padding-top: 0 !important;
}

.leisure_spa .carousel3 img {
  height: 600px;
}

.leisure_park .carousel3 .carousel {
  margin-top: 0 !important;
}

.leisure_park .carousel3 button {
  width: 12px !important;
}

.leisure_games .container {
  max-width: 2200px;
  padding: 0 100px;
}

.leisure_games .help {
  padding-top: 20px !important;
  padding-bottom: 50px !important;
}

.leisure_spa .carousel-indicators button {
  width: 12px !important;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .leisure .help {
    padding: 30px 0 !important;
  }
  .leisure .inox {
    padding-bottom: 0;
  }
  .leisure_inox .carousel3 .text {
    position: absolute;
    bottom: 0px;
    padding: 10px 0;
  }
  .leisure_inox .carousel3 h3 {
    font-size: 15px;
    height: fit-content;
  }
  .leisure_inox .carousel3 .text .container {
    height: 55px;
  }
  .leisure_inox .button2 button {
    width: 130px !important;
  }
  .leisure_spa .carousel3 img {
    height: 300px;
  }
  .leisure_games .container {
    max-width: unset;
    padding: 0 20px;
  }
  .leisure .reverse .text {
    margin-left: 0px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .leisure .reverse .text {
    margin-left: 0px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1550px) {
  .leisure .reverse .text {
    margin-left: 35%;
  }
}
