.banner_video {
  height: 100vh;
}

.banner_video video {
  height: 100vh !important;
  object-fit: cover;
}

.banner_video .overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.banner_video .banner-text {
  position: relative;
  margin-top: -320px;
  z-index: 10;
  background-color: none;
}

.banner_video .banner-text h1 {
  font-style: italic;
  color: #f9f7f2;
  line-height: 100px;
  letter-spacing: 10px;
}

.banner_video .banner-text p {
  font-size: 20px !important;
  color: #f9f7f2;
}

.banner_video .social-media {
  display: flex;
  float: right;
  align-items: end;
  height: 310px;
  margin-top: 10px;
}

.banner_video .social-media a {
  color: #fff;
  font-size: 30px;
  padding: 0 10px;
}

@media (max-width: 575.98px) {
  .banner_video .banner-text h1 {
    line-height: 45px;
  }
  .banner_video .banner-text p{
    font-size: 16px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .banner_video .banner-text h1{
    line-height: 80px;
  }
  .banner_video .banner-text {
    margin-top: -385px;
  }
}
