.carousel3 img {
  height: 800px;
  object-fit: cover;
}

.carousel3 hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 45px 0 0 40px;
  opacity: 1;
}
.carousel3 .text {
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  bottom: 90px;
}
.carousel3 .text .container {
  display: flex;
  height: 90px;
  align-items: center;
  width: 100%;
}

.carousel3 h2 {
  font-size: 70px;
  font-family: Didot;
  color: #dbb85c;
  text-align: center;
  width: 100%;
}

.carousel3 h3 {
  font-size: 25px;
  font-family: Avenir;
  color: #fff;
  width: 100%;
  margin-bottom: 0;
}

.carousel3 .carousel-control-prev,
.carousel3 .carousel-control-next {
  display: none;
}

.carousel3 .carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  border: 2px solid #d2ab66;
  margin-right: 8px;
  border-radius: 50%;
}

.carousel3 .carousel-indicators {
  bottom: 20px;
}

.carousel3 .carousel-indicators .active {
  background-color: #d2ab66 !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .carousel3 h2 {
    font-size: 35px;
    text-align: center;
    width: 100%;
    margin-top: 15px;
  }
  .carousel3 img {
    height: 300px;
  }
  .carousel3 h3 {
    font-size: 18px;
    line-height: 25px;
    height: 75px;
    padding: 0 15px;
  }
  .carousel3 .carousel-indicators {
    bottom: -25px;
  }
  .carousel3 .carousel-indicators [data-bs-target] {
    width: 8px !important;
    height: 8px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
