.event_info h3{
    font-size: 35px;
    font-family: Didot;
    font-weight: 600;
}

.event_info p{
    font-size: 25px;
    line-height: 40px;
}

.event_info ul li{
    font-size: 25px;
    line-height: 40px;
}

.event_info .card{
    width: 75%;
    margin: 40px auto;
    border: none;
    border-radius: 20px;
    padding: 50px 50px 30px;
}

.event_info .card img{
    height: 80px;
    object-fit: contain;
}

.event_info .button2 button{
    border-radius: 50px;
    font-weight: 700;
    margin-top: 20px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .event_info .card {
        width: 95%;
        margin: 20px auto;
        padding: 20px 20px 10px;
    }
    .event_info .card img {
        height: 50px;
    }
    .event_info p {
        line-height: 25px;
        margin-bottom: 15px !important;
    }
    .event_info ul li {
        font-size: 15px;
        line-height: 25px;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1439.98px) {

  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }