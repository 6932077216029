.memoirs hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 40px;
  opacity: 1;
  margin-left: 30px;
}

.memoirs h2 {
  color: #dbb85c;
  text-align: center;
}

.memoirs .owl-dots {
  display: none;
}

.memoirs .owl-carousel .owl-nav button.owl-prev,
.memoirs .owl-carousel .owl-nav button.owl-next {
  /* border: 2px solid #d2ab66; */
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.memoirs .owl-carousel .owl-nav button.owl-prev span {
  position: absolute;
  left: -50px;
  font-size: 90px;
  margin-top: -400px;
  color: #d2ab66;
}

.memoirs .owl-carousel .owl-nav button.owl-next span {
  position: absolute;
  right: -50px;
  font-size: 90px;
  margin-top: -400px;
  color: #d2ab66;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .memoirs h2 {
    font-size: 35px;
    margin: 0 auto;
  }
  .memoirs .text h3 {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .memoirs .text p {
    font-size: 14px !important;
  }
  .memoirs .text {
    padding: 15px !important;
  }
  .memoirs .view-btn button {
    font-size: 18px;
    /* width: 170px; */
    height: 50px;
    margin: 20px auto 0px;
  }
  .memoirs .owl-carousel .owl-nav button.owl-prev span {
    left: 5px;
    font-size: 65px;
    margin-top: -300px;
  }
  .memoirs .owl-carousel .owl-nav button.owl-next span {
    right: 5px;
    font-size: 65px;
    margin-top: -300px;
  }
  .memoirs .owl-carousel .owl-nav {
    position: absolute;
    width: 100%;
  }
  .memoirs .owl-theme .owl-nav [class*='owl-']:hover{
    background: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
