.cooking hr {
  border-top: 4px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 30px 0 0 20px;
  opacity: 1;
}

.cooking h2 {
  color: #dbb85c;
  text-align: center;
  white-space: nowrap;
}

.cooking img {
  height: 700px;
  object-fit: cover;
}

.cooking .text {
  position: relative;
  top: -600px;
  display: flex;
  justify-content: end;
}

.cooking h3 {
  background-image: url("../../../public/img/launch/text-bg.png");
  background-size: 100% 100%;
  height: 65px;
  width: 450px;
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Avenir;
  font-weight: 400;
  font-size: 25px;
  justify-content: center;
}

.cooking .button2 {
  text-align: center;
  width: 100%;
  position: relative;
  top: -150px;
}

.cooking button {
  font-family: Avenir;
  width: 200px !important;
}

.cooking .carousel-control-prev,
.cooking .carousel-control-next {
  display: none;
}

.cooking .carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
}

.cooking .carousel-indicators [data-bs-target] {
  width: 12px !important;
  height: 12px;
  border: 2px solid #d2ab66;
  margin-right: 8px;
  border-radius: 50%;
}

.cooking .carousel-indicators {
  bottom: 50px;
}

.cooking .carousel-indicators .active {
  background-color: #d2ab66 !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .cooking h2 {
    font-size: 35px;
    width: 100%;
  }
  .cooking h3 {
    height: 45px;
    width: 270px;
    font-size: 16px !important;
  }
  .cooking img {
    height: 350px;
  }
  .cooking .text {
    top: -300px;
  }
  .cooking button {
    width: 135px !important;
    font-size: 14px !important;
    height: 40px;
  }
  .cooking .button2 {
    top: -115px;
  }
  .cooking .col-md-6 {
    height: 350px;
  }
  .cooking .main-pad {
    padding: 0 !important;
  }
  .cooking .carousel-indicators {
    bottom: -33px;
  }
  .cooking .carousel-indicators [data-bs-target] {
    width: 7px !important;
    height: 7px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .cooking h3 {
    height: 55px;
    width: 360px;
    font-size: 20px;
  }
  .cooking button {
    width: 160px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
