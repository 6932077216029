.card12 .card {
    border: none;
}
.card12 .card .text{
    width:90%;
    margin: 0 auto 20px;
    position: relative;
    padding: 30px;
    box-shadow: 0px 4px 20px 0 #d2ab66;
    text-align: center;
    margin-top: -50px;
    background-color: #fff;
}

.card12 .card .text p{
    font-size: 18px !important  ;
}